<template>
  <v-container id="about" tag="section">
    <base-subheading>About</base-subheading>

    <p>
      Because I'm looking to get a new DAC (my current one is the one pictured above <tt>:-)</tt>)
      I thought I'd collect and organize info on what is available these days.

      It turns out there's a huge selection of DACs (Digital to Analog Converters), streamers, and network players on the market today.
    </p>
    <p>
      So far I've listened to the Auralic Vega, Arcam ST60, Naim ND5 XS2, with more to follow.
    </p>
  </v-container>
</template>

<script>
  export default {
    name: 'HomeAbout',
  }
</script>
